<template>
  <section id="container" class="gnb_pdt">
    <div class="title_wrap">
      <h1>공지사항 {{ isNew ? '등록' : '수정' }}</h1>
    </div>
    <div class="contents_wrap">
      <ul class="filter_list">
        <li>
          <dd>
            <label>공지 구분<span class="forg"> *</span></label>
            <ul>
              <li>
                <kendo-dropdownlist
                    ref="boardGbnRef"
                    style="width: 100%"
                    v-model="boardInfo.boardGbn"
                    :data-source="$store.state.codeRequestStore.search.combobox.boardGbn.data"
                    :option-label="'전체'"
                    :data-text-field="'cdNm'"
                    :data-value-field="'cdId'"
                >
                </kendo-dropdownlist>
              </li>
            </ul>
          </dd>
          <dd>
            <label>상단공지</label>
            <ul>
              <li>
                <common-radio-group
                    ref="alwYnRef"
                    v-model="boardInfo.alwYn"
                    :data-items="[{ label : 'Y' , value : 'Y',},{ label : 'N' , value : 'N',}]"
                ></common-radio-group>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd>
            <label>조회 구분<span class="forg"> *</span></label>
            <ul>
              <common-radio-group
                  ref="viewAllYnRef"
                  v-model="boardInfo.viewAllYn"
                  :data-items="[{ label : '모든업체' , value : 'Y',},{ label : '선택업체' , value : 'N',}]"
              ></common-radio-group>
              <button v-show="boardInfo.viewAllYn ==='N'"
                      @click="$refs.searchCmpyForBoardWindowRef.kendoWidget().center().open()"
                      class="mid_btn">회사선택</button>
            </ul>
          </dd>
          <dd>
            <label>공지기간 <span class="forg">*</span></label>
            <ul class="w50">
              <common-range-datepicker
                  ref="dtRef"
                  v-model="boardInfo.VModelDt">
              </common-range-datepicker>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label style="width: 9.5%;">공지 업체<span class="forg"> *</span></label>
            <ul style="width:89%">
              <li v-if="boardInfo.viewAllYn ==='N'">
                <div class="txt_company w100">
                  <p v-for="item in selectedCmpyListDataSource">{{ item.cmpyNm }}</p>
                </div>
              </li>
              <li v-else-if="boardInfo.viewAllYn ==='Y'">
                <div class="txt_company w100">
                  <p>모든업체</p>
                </div>
              </li>
            </ul>
          </dd>
        </li>
        <li v-if="!isNew">
          <dd>
            <label>작성자</label>
            <ul>
              <li>
                {{ boardInfo.frstRgstNm }}
              </li>
            </ul>
          </dd>
          <dd>
            <label>작성일</label>
            <ul>
              <li>
                {{ boardInfo.frstRgstDt }}
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label class="w10">제목<span class="forg"> *</span></label>
            <ul class="w90">
              <li>
                <div>
                  <input ref="titleRef"
                         v-model="boardInfo.title"
                         class="k-textbox w100"
                         maxlength="150"
                         placeholder="제목을 입력해주세요. (150자)">
                </div>
              </li>
            </ul>
          </dd>
        </li>
        <li>
          <dd class="w100">
            <label class="w10">내용<span class="forg"> *</span></label>
            <ul class="w90">
              <li>
                <div>
                  <kendo-editor
                      ref="kendoEditorContentsHtmlRef"
                      class="w100"
                      :tools="editor.settings.editorTools"
                      :resizable-content="true"
                      :resizable-toolbar="false"
                      v-model="boardInfo.contentsHtml"
                      style="height:280px;"
                      rows="10"
                      cols="30"
                      required>
                  </kendo-editor>
                </div>
              </li>
              <span style="color : gray" class="fr">(1450자 이내)</span>
            </ul>
          </dd>
        </li>
        <li>
        <dd><label>첨부파일</label>
          <ul>
            <li>
              <common-file-upload
                  ref="fileIdRef"
                  :url="`${ApiConfig.efsDomain}/efs/file/upload`"
                  :download-domain="ApiConfig.efsDomain"
                  :old-file-id="boardInfo.fileId"
                  v-model="boardInfo.file"
                  :multiple="true"
                  :maxfile="5"
                  :maxfile-size-mb="100"
                  :layout="'SIMPLE'"
              >
              </common-file-upload>
              <div style="color : gray">(첨부파일은 최대 5개, 40MB 미만이여야합니다.)<br>
              gif, jpg, png, pdf, xlsx, xls, docx, doc, hwp, txt, pptx, ppt, jfif 확장자만 가능합니다.</div>
            </li>
          </ul>
        </dd>
      </li>
      </ul>
    </div>
    <div class="button_area w30">
      <ul>
        <li class="w30">
          <button class="large_btn" @click="$router.go(-1)">취소</button>
        </li>
        <li class="w30">
          <button class="large_btn orange" @click="onClickboardInfoSave">저장</button>
        </li>
      </ul>
    </div>
    <kendo-window
        ref="searchCmpyForBoardWindowRef"
        :title="'공지 업체 선택'"
        :modal="true"
        :width="'900px'"
        style="display:none;  "
        @open="openSearchCmpyForBoardWindow"
        @close="closeSearchCmpyForBoardWindow"
    >
      <div class="pop-container">
        <div class="pop-conts">
          <ul class="filter_list srm_check">
            <li>
              <dd class="w100"><label class="pop_left_title w10">회사 </label>
                <ul class="pop_right_area w90">
                  <li>
                    <common-radio-group
                        ref="cmpyGbnRef"
                        v-model="searchCmpyGbn"
                        @click="e=>{
                          searchCmpyGbn = e.value
                          getCmpyCdList(e)
                        }"
                        :data-items="$store.state.codeRequestStore.search.combobox.cmpyGbn.data.filter(item=> item.cdId===constant.cmpyGbn.client)"
                        :data-value-field="'cdId'"
                        :data-text-field="'cdNm'"
                    ></common-radio-group>
                  </li>
                </ul>
              </dd>
            </li>
          </ul>
        </div>
        <div class="pop_notice_company">
          <kendo-listbox
              ref="leftListBoxRef"
              @remove="removeLeftListBox"
              :data-source="cmpyListDataSource"
              data-text-field="cmpyNm"
              id="optional"
              :draggable="true"
              :selectable="'multiple'"
              :connect-with="'selected'"
              :drop-sources="['selected']"
              :toolbar-tools="['transferTo', 'transferFrom', 'transferAllTo', 'transferAllFrom']">
            <!-- <option v-for="cmpy in cmpyList" v-if="checkCmpy(cmpy)">{{cmpy.cmpyNm}}</option> -->
          </kendo-listbox>
          <kendo-listbox
              ref="rightListBoxRef"
              @remove="removeRightListBox"
              :data-source="selectedCmpyListDataSource"
              data-text-field="cmpyNm"
              id="selected"
              :draggable="true"
              :selectable="'multiple'"
              :connect-with="'optional'"
              :drop-sources="['optional']">
          </kendo-listbox>
        </div>
        <div class="button_area">
        <button @click="e=>{
          updateSelectedCmpyListDataSource()
          $refs.searchCmpyForBoardWindowRef.kendoWidget().close()
        }" class="large_btn orange">확인</button>
        </div>
      </div>
    </kendo-window>
  </section>
</template>

<script>

import ApiUtil from '/src/api/api.util'
import ApiConfig from '/src/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'

export default {
  name      : 'BoardSave',
  watch : {
    logiDepth2List : function(newValue, oldValue){
      EtnersCommonUtil.setRowNumber(newValue)
    }
  },
  created () {
    if (!!this.$route.params.boardId) {
      this.isNew = false
      this.getLogiManageDetail()
    }else {
      this.boardInfo.VModelDt = {
        start : null,
        end : null
      }
    }
  },
  methods   : {
    openSearchCmpyForBoardWindow(){
      const vm = this
      vm.isWindowOpen = true
      vm.searchCmpyGbn = vm.constant.cmpyGbn.client
      vm.initSelectedCmpyListDataSource = _.cloneDeep(vm.selectedCmpyListDataSource)
      vm.getCmpyCdList()
    },
    closeSearchCmpyForBoardWindow(){
      const vm = this
      vm.isWindowOpen = false
      vm.selectedCmpyListDataSource = _.cloneDeep(vm.initSelectedCmpyListDataSource)
    },
    updateSelectedCmpyListDataSource : function (){
      const vm = this
      vm.initSelectedCmpyListDataSource = _.cloneDeep(vm.selectedCmpyListDataSource)
    },
    getCmpyCdList: function (e) {
      const vm = this
      let param = {
        loginUserToken: sessionStorage.loginUserToken,
        skip: 1,
        take: 10,
        page: 1,
        pageSize: 10,
        searchCmpyGbn: vm.searchCmpyGbn
      }
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/drop-down/company`, param)
      .then((response) => {
        if (
            response.data === undefined ||
            response.data.resultStatus === undefined
        ) {
          kendo.alert("데이터가 정상적으로 조회되지 않았습니다.");
          return false;
        }
        if (response.data.resultStatus.messageCode !== "2000") {
          kendo.alert(response.data.resultStatus.messageText);
          return false;
        }

        vm.selectedCmpyListDataSource?.forEach((item,i)=>{
          for(let i in response.data.resultData){
            if(response.data.resultData[i].cmpyCd === item.cmpyCd){
              response.data.resultData.splice(i, 1)
              return
            }
          }
        })
        vm.cmpyListDataSource = response.data.resultData
      })
      .catch((error) => {
        console.log(error);
      });
    },
    /**
     * 왼쪽 리스트박스에서 오른쪽으로 넘길 떄 실행되는 method
     */
    removeLeftListBox: function(evt){
      const vm = this
      const cmpys = evt.dataItems

      cmpys.forEach((cmpy) => {
        let cmpyData = {
          cmpyCd: cmpy.cmpyCd,
          cmpyNm: cmpy.cmpyNm,
          cmpyGbn : cmpy.cmpyGbn,
        }
        vm.selectedCmpyListDataSource.push(cmpyData)
        for(let i in vm.cmpyListDataSource){
          if(vm.cmpyListDataSource[i].cmpyCd === cmpy.cmpyCd){
            vm.cmpyListDataSource.splice(i,1)
            return
          }
        }
      })
      vm.$refs.rightListBoxRef.kendoWidget().setDataSource(vm.selectedCmpyListDataSource)
    },

    /**
     * 오른쪽 리스트박스에서 왼쪽으로 넘길 때 실행되는 method
     */
    removeRightListBox: function(evt){
      const vm = this
      const cmpys = evt.dataItems

      cmpys.forEach((cmpy) => {
        const cmpyData = {
          cmpyCd: cmpy.cmpyCd,
          cmpyNm: cmpy.cmpyNm,
          cmpyGbn : cmpy.cmpyGbn,
        }
        if(vm.searchCmpyGbn === cmpy.cmpyGbn || vm.searchCmpyGbn ===''){
          vm.cmpyListDataSource.push(cmpyData)
        }
        for(let i in vm.selectedCmpyListDataSource){
          if(vm.selectedCmpyListDataSource[i].cmpyCd === cmpy.cmpyCd){
            vm.selectedCmpyListDataSource.splice(i,1)
            return
          }
        }
      })
      vm.$refs.leftListBoxRef.kendoWidget().setDataSource(vm.cmpyListDataSource)
    },
    getLogiManageDetail : function() {
      const vm = this

      ApiUtil.query(`${ApiConfig.efsDomain}/efs/board/${vm.$route.params.boardId}`)
      .then(function (response) {
            if (response.data.resultStatus.messageCode === '2000') {
              response.data.resultData.file.forEach(fileItem=>{
                fileItem.src = ApiConfig.efsDomain + "/efs/file/"+ fileItem.fileId + "/" + fileItem.fileSeqNo
                fileItem.name = fileItem.orgFileNm
                fileItem.size = fileItem.fileSize
              })
              vm.boardInfo = response.data.resultData
              vm.selectedCmpyListDataSource = response.data.resultData.boardCmpyParamList ?? []
              vm.boardInfo.VModelDt = {
                start : EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(vm.boardInfo.srtDt)),
                end :  EtnersDateTimeUtil.lookupDateYYYYMMDD(EtnersCommonUtil.extractOnlyNumbers(vm.boardInfo.endDt))
              }
              vm.$refs.dtRef.widget().range(vm.boardInfo.VModelDt)
            } else {
              kendo.alert(response.data.resultStatus.messageText)
            }
          },
      )
    },
    async onClickboardInfoSave () {
      const vm = this
      if (vm.validator()) {
        await kendo.confirm('저장 하시겠습니까?').done(
          async function () {
            await vm.logiManageSave()
          })
      }
    },
    async logiManageSave() {

      const vm = this

      try {
        vm.$store.dispatch('LOADING', true)
        let params = { loginUserToken: vm.$store.state.loginStore.lutk }

        if(vm.boardInfo.file?.length > 0){
          let fileSaveReslut = await vm.$refs.fileIdRef.saveFile(params)
          vm.$store.dispatch('LOADING', false)
          vm.boardInfo.fileId = EtnersCommonUtil.isNotEmpty(fileSaveReslut.fileId) ? fileSaveReslut.fileId : vm.boardInfo.fileId
        }else {
          vm.boardInfo.fileId = null
        }

        vm.logiDepth2List.forEach(item=>{
          item.useYn = item.VModelUseYn ? 'Y' : 'N'
        })
        vm.boardInfo.boardCmpyParamList = vm.selectedCmpyListDataSource
        !!vm.boardInfo.VModelDt.start ? vm.boardInfo.srtDt = EtnersDateTimeUtil.dateToYYYYMMDD(vm.boardInfo.VModelDt.start) : ''
        !!vm.boardInfo.VModelDt.end ? vm.boardInfo.endDt = EtnersDateTimeUtil.dateToYYYYMMDD(vm.boardInfo.VModelDt.end) : ''

        if(vm.isNew){
          ApiUtil.post(`${ApiConfig.efsDomain}/efs/board`, vm.boardInfo).then(response => {
            vm.$store.dispatch('LOADING', false)
            if (!response.data?.resultStatus) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }

            vm.$store.dispatch('API_COUNT', 0)
            vm.$router.push({
              name: 'BoardDetail',
              params: {
                boardId : response.data.resultData.boardId,
              }
            })
          })
        }else {
          ApiUtil.put(`${ApiConfig.efsDomain}/efs/board/${vm.$route.params.boardId}`, vm.boardInfo).then(response => {
            vm.$store.dispatch('LOADING', false)
            if (!response.data?.resultStatus) {
              kendo.alert('데이터가 정상적으로 처리되지 않았습니다.')
              return false
            }
            if (response.data.resultStatus.messageCode !== '2000') {
              kendo.alert(response.data.resultStatus.messageText)
              return false
            }

            vm.$store.dispatch('API_COUNT', 0)
            vm.$router.push({
              name: 'BoardDetail',
              params: {
                cmpyCd : vm.$route.params.cmpyCd,
                logiDepth1 : vm.$route.params.logiDepth1
              }
            })
          })
        }
      }catch (e) {
        console.error(e)
        vm.$store.dispatch('LOADING', false)
      }
    },
    validator(){
      const vm = this
      if(!vm.boardInfo.boardGbn){
        kendo.alert("공지구분을 선택해주세요.").bind('close', function() {
          vm.$refs.boardGbnRef.kendoWidget().toggle()
        })
        return
      }
      if(!vm.boardInfo.viewAllYn){
        kendo.alert("조회구분을 선택해주세요.").bind('close', function() {
          vm.$refs.viewAllYnRef.widget().focus()
        })
        return
      }
      if(vm.boardInfo.viewAllYn==='N' && vm.selectedCmpyListDataSource.length ===0){
        kendo.alert("공지할 회사를 선택해주세요.").bind('close', function() {
          vm.$refs.searchCmpyForBoardWindowRef.kendoWidget().center().open()
        })
        return
      }
      if(!vm.boardInfo.VModelDt.start){
        kendo.alert("공지 시작일자를 선택해주세요.").bind('close', function() {
          vm.$refs.dtRef.widget().openStartDatePicker()
        })
        return
      }
      if(!vm.boardInfo.VModelDt.end){
        kendo.alert("공지 종료일자를 선택해주세요.").bind('close', function() {
          vm.$refs.dtRef.widget().openEndDatePicker()
        })
        return
      }
      if(!vm.boardInfo.title){
        kendo.alert("제목을 입력해주세요.").bind('close', function() {
          vm.$refs.titleRef.focus()
        })
        return
      }

      if(!vm.boardInfo.contentsHtml){
        kendo.alert("내용을 입력해주세요.").bind('close', function() {
          vm.$refs.kendoEditorContentsHtmlRef.kendoWidget().focus()
        })
        return
      }

      return true
    },
  },
  data      : function () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const constant = {
      cmpyGbn : {
        client : '02'
      }
    }
    const vm = this
    return {
      vm,
      ApiConfig : { efsDomain : ApiConfig.efsDomain },
      constant : constant,
      isNew : true,
      isWindowOpen : false,
      baseData : {},
      uiData : {},
      searchCmpyGbn : constant.cmpyGbn.client,
      cmpyListDataSource : [],
      selectedCmpyListDataSource : [],
      initSelectedCmpyListDataSource : [],
      editor: {
        settings: {
          editorTools: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "justifyLeft",
            "justifyCenter",
            "justifyRight",
            "justifyFull",
            "insertUnorderedList",
            "insertOrderedList",
            "indent",
            "outdent",
            "tableWizard",
            "createTable",
            "addRowAbove",
            "addRowBelow",
            "addColumnLeft",
            "addColumnRight",
            "deleteRow",
            "deleteColumn",
            {
              // kendo ui 버전이 낮아 image resize 기능 사용 불가
              name: "image",
              exec: function(e) {
                e.stopImmediatePropagation() // 기존의 kendo-insert용 팝업을 막기 위함
                let uploadInput = kendo.jQuery("<input type='file' />")
                uploadInput.click()
                uploadInput.on("change", uploadInputChange)

                function uploadInputChange (ev) {
                  if (this.files && this.files[0]) {
                    let reader = new FileReader()
                    reader.onload = imageIsLoaded;
                    reader.readAsDataURL(this.files[0])
                  }
                }
                function imageIsLoaded (ev) {
                  let base64Src = ev.target.result;
                  let img = kendo.jQuery("<img src='" + base64Src + "' />")[0];

                  vm.$refs.kendoEditorContentsHtmlRef.kendoWidget().paste(img.outerHTML)
                  img.remove()
                }
              }
            }
          ]
        }
      },
      boardInfo  : {
        alwYn : 'N'
      },
      rowItem : {
        data : {},
        index : null,
      },
      logiDepth2List : [],
      logiDepth2GridColumns : [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2',
          title: '코드번호',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'logiDepth2DisNm',
          title: '항목명',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'seqNo',
          title: '항목순서',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'useYn',
          title: '사용여부',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
      ],
    }
  },
}
</script>

<style scoped>
.k-i-my-insert-image:before {
  content: "\e501"
}
.editor-wrap {
  position: relative;
}
.editor-wrap .k-overlay {
  position: absolute;
  opacity: 0;
  z-index: 1;
}
.editor-wrap .k-resize-se {
  position: absolute;
  z-index: 2;
  bottom: 6px;
  right: 6px;
}
.editor-wrap > .k-editor {
  height: 100%;
}
</style>
